































































import { defineComponent } from "@vue/composition-api";
import { mapStores } from "pinia";
import { mapActions, mapState } from "vuex";
import { logger } from "@/logger";
import { usePopupStore } from "@/pinia/popup";
import { CoachService } from "@/services/coach";

type Data = {
	lidId?: number;
	loading: boolean;
	file?: Blob;
	imageShowing?: string;
};

export default defineComponent({
	name: "GebruikerProfielAfbeelding",
	data(): Data {
		return {
			loading: false,
			lidId: undefined,
			imageShowing: undefined,
			file: undefined,
		};
	},
	computed: {
		...mapStores(usePopupStore),
		...mapState("user", ["user"]),
	},
	created() {
		this.reset();

		this.lidId = Number(this.$router.currentRoute.params.lidId);
		if (this.user.profileImg) this.imageShowing = this.user.profileImg;

		if (this.lidId) {
			this.getUser(+this.lidId)
				.then((user) => {
					this.imageShowing = user.profileImg || "static/img/default-avatar.png";
				})
				.catch(logger.error);
		}
	},

	methods: {
		...mapActions("user", ["getUser"]),
		imageFileChange(file: Blob) {
			this.file = file;
		},
		reset() {
			// @ts-expect-error Apply not correctly typed
			Object.assign(this.$data, this.$options.data?.apply(this));
		},
		saveImage() {
			if (!this.lidId) return;
			if (!this.file) return;
			this.loading = true;

			return new CoachService()
				.updateLidProfileImage(this.lidId, this.file)
				.then(() => {
					return this.popupStore.open({
						title: "Gelukt!",
						body: "De profielafbeelding is succesvol opgeslagen",
						buttons: {
							confirm: "Terug naar lid",
						},
						callback: () => {
							this.getUser(this.lidId);
							this.$router.push({
								name: "Gebruiker persoonlijke gegevens",
								params: {
									id: String(this.lidId),
								},
							});
						},
					});
				})
				.catch((error) => {
					const message = error;
					this.popupStore.showError(
						`Er ging iets mis bij het opslaan van de profiel afbeelding ${message}.<br/>Probeer het later nog eens.`,
					);
				})
				.finally(() => {
					this.loading = false;
				});
		},
	},
});
